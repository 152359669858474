<template>
  <div>
    <List
      :date="date"
      :iniDate="iniDate"
      :endDate="endDate"
      :employeeId="employeeId"
      ref="elList"
    ></List>
  </div>
</template>
<script>
import List from "../quotes/List";

export default {
  components: {
    List
  },

  // directives
  // filters

  props: {
    //
  },

  data: () => ({
    iniDate: null,
    endDate: null,
    employeeId: null,
    date: null
  }),

  computed: {
    //
  },

  watch: {
    $route() {
      this.$refs.elList.gl();
    }
  },

  created() {
    this.iniDate = this.$route.query.iniDate || null;
    this.endDate = this.$route.query.endDate || null;
    this.date = this.$route.query.date || null;
    this.employeeId = this.$route.query.employeeId || null;
  },

  mounted() {
    //
  },

  methods: {
    //
  }
};
</script>

<style></style>
